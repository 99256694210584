<template>
  <TableCard
    :rows="displayedRows"
    :headers="headers"
    display-pagination
    :is-loading="isLoading"
    :pagination="pagination"
    @update:current_page="$emit('update:current_page', $event)"
  >
    <template #title>
      {{ $t("app.logs") }}
    </template>

    <template v-if="!hideActions" #title-actions>
      <div class="flex">
        <VSelect
          :model-value="currentOption"
          :options="displayedOptions"
          class="w-96 flex mr-2"
          :placeholder="$t('app.select_month')"
          @update:modelValue="$emit('update:option', $event)"
        />

        <VButton
          icon="fa-file-download fa-lg"
          class="btn-primary"
          @click="$emit('download')"
        />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.displayed_resource_type="{ item }">
      {{ item.displayed_resource_type }}
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.action="{ item }">
      <VChip
        :text="item.displayed_action"
        class="w-full"
        :class="getActionColor(item.action)"
      />
    </template>
  </TableCard>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import TableCard from "@/components/tables/TableCard";
import VSelect from "@/components/inputs/VSelect";
import VChip from "@/components/VChip";
import VButton from "@/components/VButton";
import useColor from "@/composables/useColor";
import months from "@/constants/months";

export default {
  components: {
    TableCard,
    VSelect,
    VButton,
    VChip
  },
  props: {
    logType: {
      type: String,
      default: "Login",
      validator: value =>
        ["Profile", "Login", "User", "Site", "Role", "Department"].includes(
          value
        )
    },
    rows: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    currentOption: {
      type: [String, Number],
      default: ""
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:current_page", "update:option", "download"],
  setup(props) {
    // MISC
    const { t } = useI18n();
    const store = useStore();
    const moment = inject("moment");
    const { getActionColor } = useColor();

    // COMPUTED
    const headers = computed(() => {
      let headers = [
        {
          text: t("app.date"),
          value: "date"
        },
        {
          text: t("app.time"),
          value: "time"
        },
        {
          text: t("app.users", 1),
          value: "user"
        },
        {
          text: t("app.ip_address"),
          value: "ip"
        },
        {
          text: t("app.type"),
          value: "displayed_resource_type"
        },
        {
          text: t("app.resource_name"),
          value: "resource_name"
        },
        {
          text: t("app.action"),
          value: "action"
        }
      ];

      if (props.logType !== "Profile") {
        headers = headers.filter(h => h.value !== "displayed_resource_type");
      }

      if (props.logType === "Login") {
        return headers.filter(header => header.value !== "resource_name");
      }

      return headers;
    });

    const dateFormat = computed(() => {
      return store.getters["app/date_format"];
    });

    const displayedRows = computed(() => {
      return props.rows.map(row => ({
        ...row,
        date: moment(row.logged_at).format(dateFormat.value),
        time: moment(row.logged_at).format("HH:mm:ss"),
        displayed_resource_type: t(`backend.${row.resource_type}`),
        displayed_action: t(`backend.${row.action}`)
      }));
    });

    const displayedOptions = computed(() => {
      return props.options.map((option, index) => ({
        text: `${t(`app.${months[option.month - 1]}`)} ${option.year}`,
        value: index
      }));
    });

    return {
      getActionColor,
      headers,
      displayedRows,
      displayedOptions
    };
  }
};
</script>
